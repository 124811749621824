import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AlertController, IonicModule, ModalController } from '@ionic/angular';
import { ColumnMode, NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { Subscription } from 'rxjs';

import { ApiService } from 'src/services/api.service';
import { MessageService } from 'src/services/message.service';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule, NgxDatatableModule, NgxTippyModule],
  selector: 'app-upsert-survey-modal',
  templateUrl: './upsert-survey-modal.component.html',
  styleUrls: ['./upsert-survey-modal.component.scss'],
})
export class UpsertSurveyModalComponent implements OnInit {
  @Input() survey?: any = { style: {} };
  @Input() shouldSave = true;

  surveys: any[] = [];
  isLoading = false;
  ColumnMode = ColumnMode;
  routerSub: Subscription;
  isAnalyticsModalOpen = false;
  isCreateSurveyModalOpen = false;
  currentSurveyId?: string;

  constructor(
    private apiService: ApiService,
    private msgSrvc: MessageService,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
  ) {}

  ngOnInit() {
    if (!this.survey?.id) {
      this.survey = {
        type: 'csat',
        style: {
          buttonLayout: 'inline',
          postSubmissionMessage: 'Thanks for letting us know!',
          selectionOptions: 'emojiFaces',
          buttonLabel: 'Fill out survey',
        },
      };
    }
  }

  dismiss(data?: any) {
    this.modalCtrl.dismiss(data);
  }

  async upsertSurvey() {
    this.isLoading = true;

    try {
      if (this.shouldSave) {
        if (this.survey.id) {
          await this.apiService.patchPromise(`/surveys/${this.survey.id}`, this.survey);
        } else {
          await this.apiService.postPromise(`/surveys`, this.survey);
        }
      }

      this.dismiss(this.survey);
    } catch (err) {
      this.msgSrvc.showError(err);
    } finally {
      this.isLoading = false;
    }
  }

  async deleteSurvey(surveyId: string) {
    const alert = await this.alertCtrl.create({
      header: 'Delete survey',
      subHeader: 'Are you sure you want to delete this survey?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Yes',
          handler: async () => {
            try {
              await this.apiService.deletePromise(`/surveys/${surveyId}`);

              this.dismiss();
            } catch (err) {
              this.msgSrvc.showError(err);
            } finally {
              this.isLoading = false;
            }
          },
        },
      ],
    });

    await alert.present();
  }
}
