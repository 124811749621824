<ion-header class="modal-header">
  <div class="modal-title">
    <h3>{{ survey.id ? 'Edit' : 'Create' }} survey</h3>
  </div>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button fill="clear" shape="round" class="close-button" (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div style="padding: 18px">
    <ng-container *ngIf="shouldSave">
      <div>
        <ion-label> Name </ion-label>
        <ion-input placeholder="e.g. Survey name" [(ngModel)]="survey.name" class="input-field"> </ion-input>
      </div>

      <ion-label> Description </ion-label>
      <ion-textarea placeholder="e.g. Survey description" [(ngModel)]="survey.description" class="input-field">
      </ion-textarea>
    </ng-container>

    <div style="margin-top: 10px">
      <ion-label> Type </ion-label>
      <br />
      <ion-select
        interface="popover"
        [(ngModel)]="survey.type"
        class="input-field"
        style="margin: 0"
        [disabled]="survey.id"
      >
        <ion-select-option value="csat">CSAT score</ion-select-option>
        <ion-select-option value="nps">NPS score</ion-select-option>
      </ion-select>
    </div>

    <div style="margin-top: 10px">
      <ion-label> Style </ion-label>
      <br />
      <ion-select interface="popover" [(ngModel)]="survey.style.buttonLayout" class="input-field" style="margin: 0">
        <ion-select-option value="inline">Inline buttons</ion-select-option>
        <ion-select-option value="single">Single button with pop-up modal</ion-select-option>
      </ion-select>
    </div>

    <div style="margin-top: 10px" *ngIf="survey.style.buttonLayout === 'inline' && survey.type === 'csat'">
      <ion-label> Selection Options </ion-label>
      <br />
      <ion-select interface="popover" [(ngModel)]="survey.style.selectionOptions" class="input-field" style="margin: 0">
        <ion-select-option value="emojiFaces">Emojis  🤩 🙂 😐 😞 😡</ion-select-option>
        <ion-select-option value="numbers">Numbers  1️⃣ 2️⃣ 3️⃣ 4️⃣ 5️⃣</ion-select-option>
      </ion-select>
    </div>

    <ng-container *ngIf="survey.style?.buttonLayout === 'single'">
      <div style="margin-top: 10px">
        <ion-label> Button label </ion-label>
        <ion-input placeholder="e.g. Fill out survey" [(ngModel)]="survey.style.buttonLabel" class="input-field">
        </ion-input>
      </div>
    </ng-container>

    <div style="margin-top: 10px">
      <ion-label> Message after submission </ion-label>
      <ion-input
        placeholder="e.g. Thanks for your feedback!"
        [(ngModel)]="survey.style.postSubmissionMessage"
        class="input-field"
      >
      </ion-input>
    </div>

    <div
      style="padding-top: 15px; margin-top: 15px; border-top: 1px solid var(--ion-color-step-100)"
      class="flex-space-between"
    >
      <ion-button (click)="upsertSurvey()" [disabled]="isLoading">
        Save
        <ion-spinner name="crescent" [hidden]="!isLoading" style="margin-left: 10px"></ion-spinner>
      </ion-button>
      <ion-button
        color="danger"
        fill="clear"
        (click)="deleteSurvey(survey.id)"
        [disabled]="isLoading"
        *ngIf="survey.id"
      >
        Delete
      </ion-button>
    </div>
  </div>
</ion-content>
