<ion-header class="modal-header">
  <div class="modal-title">
    <h3>Slack survey preview</h3>
  </div>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button fill="clear" shape="round" class="close-button" (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card class="preview-container">
    <div style="padding: 18px">
      <!-- <strong>{{ titleText }}</strong>
      <ion-radio-group>
        <ion-list>
          <ion-item *ngFor="let option of radioOptions">
            <ion-radio slot="start"></ion-radio>
            <ion-label text-wrap>{{ option }}</ion-label>
          </ion-item>
        </ion-list>
      </ion-radio-group> -->
      <div class="slack-survey-container">
        <span class="slack-block-text">{{ titleText }}</span>
        <ion-select interface="popover" class="slack-section-dropdown" placeholder="Select a rating">
          <ion-select-option *ngFor="let opt of radioOptions">{{ opt }}</ion-select-option>
        </ion-select>
      </div>
      <div>
        <div style="margin: 20px 0 10px 0; font-weight: 700">Any other comments?</div>
        <textarea class="survey-text-area" rows="4"></textarea>
      </div>
    </div>
    <div style="text-align: end; padding: 16px; border-top: 1px solid #ddd">
      <ion-button color="dark" fill="outline"> Cancel </ion-button>
      <ion-button color="success"> Submit </ion-button>
    </div>
  </ion-card>
</ion-content>
