<ion-grid>
  <ion-row>
    <ion-col size="12" size-md="2">
      <div class="sticky-top">
        <ion-button
          (click)="addRichTextBlock()"
          shape="round"
          color="light"
          size="full"
          class="add-block-button"
          [disabled]="disabled"
        >
          + Rich Text
        </ion-button>
        <ion-button
          (click)="addSectionBlock()"
          shape="round"
          color="light"
          size="full"
          class="add-block-button"
          [disabled]="disabled"
        >
          + Section
        </ion-button>
        <ion-button
          (click)="addImageBlock()"
          shape="round"
          color="light"
          size="full"
          class="add-block-button"
          [disabled]="disabled"
        >
          + Image
        </ion-button>
        <ion-button
          (click)="addButtonsBlock()"
          shape="round"
          color="light"
          size="full"
          class="add-block-button"
          [disabled]="disabled"
        >
          + Buttons
        </ion-button>
        <ion-button
          (click)="addDividerBlock()"
          shape="round"
          color="light"
          size="full"
          class="add-block-button"
          [disabled]="disabled"
        >
          + Divider
        </ion-button>
        <ion-button
          (click)="addHeaderBlock()"
          shape="round"
          color="light"
          size="full"
          class="add-block-button"
          [disabled]="disabled"
        >
          + Header
        </ion-button>
        <ion-button
          (click)="addContextBlock()"
          shape="round"
          color="light"
          size="full"
          class="add-block-button"
          [disabled]="disabled"
        >
          + Context
        </ion-button>
        <ion-button
          (click)="applySurvey()"
          shape="round"
          color="light"
          size="full"
          class="add-block-button"
          [disabled]="disabled"
        >
          <span class="purple"> <ion-icon name="flash" class="add-block-icon"></ion-icon> Survey </span>
          <ion-icon name="caret-down-outline" class="add-caret" [hidden]="isLoadingSurveys"></ion-icon>
          <ion-spinner name="crescent" color="dark" [hidden]="!isLoadingSurveys" style="margin-left: 5px"></ion-spinner>
        </ion-button>

        <div class="separator"></div>
        <ion-button
          (click)="applyTemplate()"
          shape="round"
          color="light"
          size="full"
          [disabled]="disabled || isLoadingTemplates"
          class="add-block-button"
        >
          Apply template...
          <ion-icon name="caret-down-outline" class="add-caret" [hidden]="isLoadingTemplates"></ion-icon>
          <ion-spinner
            name="crescent"
            color="dark"
            [hidden]="!isLoadingTemplates"
            style="margin-left: 5px"
          ></ion-spinner>
        </ion-button>
      </div>
    </ion-col>
    <ion-col size="12" size-md="5">
      <ion-reorder-group (ionItemReorder)="onReorder($event)" [disabled]="false" style="width: 100%">
        <ng-container *ngFor="let block of blocks; let i = index">
          <ion-card class="slack-block-edit-container">
            <ion-card-header style="padding-top: 5px; padding-bottom: 5px">
              <div class="flex-space-between">
                <div class="flexed-align-center">
                  <ion-reorder></ion-reorder>
                  <span style="padding-left: 5px"
                    ><strong>{{ getBlockName(block) }}</strong></span
                  >
                </div>
                <div>
                  <ion-button fill="clear" class="btn-small" (click)="removeBlock(i)" *ngIf="blocks.length > 1">
                    <ion-icon name="trash-outline"></ion-icon>
                  </ion-button>
                  <ion-button
                    fill="clear"
                    class="btn-small"
                    (click)="duplicateBlock(i)"
                    [hidden]="block.type === 'survey'"
                  >
                    <ion-icon name="copy-outline"></ion-icon>
                  </ion-button>
                </div>
              </div>
            </ion-card-header>
            <app-slack-block-edit [block]="block" (blockChanged)="onBlockDidChange(i, $event)"> </app-slack-block-edit>
          </ion-card>
        </ng-container>
      </ion-reorder-group>
    </ion-col>

    <ion-col size="12" size-md="5">
      <div class="sticky-top">
        <h3 class="column-header">Preview:</h3>
        <ion-card class="slack-block-preview-container">
          <div class="message-preview-image">
            <img
              [src]="sendAsImage || '/assets/avatars/0.svg'"
              style="width: 44px; height: 44px; border-radius: 8px; max-width: 44px"
            />
          </div>
          <div style="width: calc(100% - 60px)">
            <span class="app-name">{{ sendAsName }}</span>
            <span class="app-badge">APP</span>
            <span class="app-timestamp">10:00 AM</span>
            <div style="margin-top: 8px"><app-slack-message [content]="htmlContent"></app-slack-message></div>
          </div>
        </ion-card>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
