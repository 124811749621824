import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

import { SlackSurvey } from 'src/models';

@Component({
  selector: 'app-slack-survey-preview',
  templateUrl: './slack-survey-preview.component.html',
  styleUrls: ['./slack-survey-preview.component.scss'],
})
export class SlackSurveyPreviewComponent implements OnInit {
  type: string;
  titleText: string;
  radioOptions: string[];
  commentsEnabled: boolean;

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
  ) {}

  ngOnInit(): void {
    const survey = this.navParams.get('survey') as SlackSurvey;
    this.type = survey.type;
    if (this.type === 'nps') {
      this.titleText = 'How likely are you to recommend us to a friend or colleague?';
      this.radioOptions = [
        '0 – Not likely at all',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10 – Extremely likely',
      ];
    } else if (this.type === 'csat') {
      this.titleText = 'How satisfied are you with our services?';
      this.radioOptions = ['1 – Not satisfied at all', '2', '3', '4', '5 – Extremely satisfied'];
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
